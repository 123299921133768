<template>
  <span style="background-color: #19211D;">
    <template>
      <v-footer color="transparent" class="mt-10 pt-10 pb-5">
        <v-card class="flex" color="transparent">
          <v-row class="mb-10">
            <v-col cols="12" lg="8" class="mx-auto">
              <v-card-text class="px-0 text-center text-white">
                <p class="text-h2">JETZT KOSTENLOS HERUNTERLADEN!</p>
                <p class="text-h4">
                  Lorem ipsum dolor sit amet, consetetur sadipscing, sed diam
                  nonumy eirmod tempor, Lorem ipsum dolor sit amet, consetetur
                  sadipscing, sed diam nonum.
                </p>
              </v-card-text>
              <v-row justify="center">
                <div>
                  <v-img
                    :src="require('../assets/storelogos/appstore.png')"
                    class="my-1"
                    contain
                    height="50"
                  />
                </div>
                <div>
                  <v-img
                    :src="require('../assets/storelogos/playstore.png')"
                    class="my-1"
                    contain
                    height="50"
                  />
                </div>
              </v-row>
            </v-col>
          </v-row>

          <v-card-text class="px-0">
            <v-row>
              <v-col cols="12" sm="8" class="mb-2">
                <a
                  v-for="item in footer"
                  :key="item.linkName"
                  :href="item.link"
                  class="text-decoration-none text-white ls-0 mx-4 text-md"
                  target="_blank"
                  >{{ item.linkName }}</a
                >
              </v-col>
              <v-col cols="4" class="mx-auto text-center">
                <p class="copyright text-white text-md ls-0">
                  © {{ new Date().getFullYear() }}
                  - Bexikon Inh. Sven Winkel
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-footer>
    </template>
  </span>
</template>
<script>
export default {
  name: "content-footer",
  props: {
    auth: Boolean,
  },
  data() {
    return {
      footer: [
        {
          linkName: "Impressum",
          link: "https://www.bexikon.de/impressum/",
        },
        {
          linkName: "Datenschutz",
          link: "https://www.bexikon.de/datenschutz/",
        },
        {
          linkName: "AGB",
          link: "https://www.bexikon.de/agb/",
        },
      ],
      icons: [
        // {
        //   icon: "fab fa-dribbble",
        // },
        // {
        //   icon: "fab fa-twitter",
        // },
        // {
        //   icon: "fab fa-instagram",
        // },
        // {
        //   icon: "fab fa-pinterest",
        // },
        // {
        //   icon: "fab fa-github",
        // },
      ],
    };
  },
};
</script>
