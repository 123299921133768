import axiosAuth from '../../../shared/configs/axios-auth'

const getDefaultState = () => {
    return {
        quests: [],
        selectedQuest: null,
        selectedQuests: false,
        newQuest: {}
    }
}

const state = getDefaultState()

const getters = {
    newQuest: state => {
        return state.newQuest
    },
    quests: state => {
        return state.quests
    },
    selectedQuest: state => {
        return state.selectedQuest
    },
    newQuest: state => {
        return state.newQuest
    },
}

const mutations = {
    newQuest: (state, payload) => {
        state.newQuest = payload
    },
    quests: (state, payload) => {
        state.quests = payload
    },
    selectedQuest: (state, payload) => {
        state.selectedQuest = payload
    },
    selectedQuests: (state, payload) => {
        state.selectedQuests = payload
    },

}

const actions = {

    getCategoryByID: ({ commit, dispatch }, id) => {
        commit('selectedQuests', true)
        axiosAuth.get('/quests/' + id, {

            })
            .then(
                res => {
                    commit('selectedQuests', false)
                    if (!res.data.message) {
                        commit('selectedQuest', res.data)
                    }
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('selectedQuests', false)

                }
            )
    },

    getCategory: ({ commit }) => {
        commit('selectedQuests', true)
        axiosAuth.get('/quests', {
                headers: {
                    version: 'v1.1'
                }
            })
            .then(
                res => {
                    commit('selectedQuests', false)
                    if (!res.data.message) {
                        commit('quests', res.data)
                    }
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('selectedQuests', false)

                }
            )
    },

    postCategory: ({ commit, dispatch }, payload) => {
        commit('updating', true)
        axiosAuth.post('/quests', payload, {
                headers: {
                    version: 'v1.1'
                }
            })
            .then(
                res => {
                    dispatch('getCategory')
                    commit('updating', false)

                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('authError', true)
                    commit('authMessage', error.response.data.message)
                    commit('updating', false)

                }
            )
    },

    editCategory: ({ commit, dispatch }, payload) => {
        commit('updating', true)
        axiosAuth.patch('/quests/' + payload.id, payload, {
                headers: {
                    version: 'v1.1'
                }
            })
            .then(
                res => {
                    dispatch('getCategory')
                    commit('updating', false)

                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('authError', true)
                    commit('authMessage', error.response.data.message)
                    commit('updating', false)

                }
            )
    },

    deleteCategory: ({ commit, dispatch }, payload) => {
        commit('updating', true)
        axiosAuth.delete('/quests/' + payload, {
                headers: {
                    version: 'v1.1'
                }
            })
            .then(
                res => {
                    dispatch('getCategory')
                    commit('updating', false)

                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('authError', true)
                    commit('authMessage', error.response.data.message)
                    commit('updating', false)

                }
            )
    },

}

export default {
    state,
    getters,
    mutations,
    actions
}