<template>
  <v-app-bar
    :color="background"
    height="auto"
    class="px-0 border-radius-xl toolbar-content-padding-y-none"
    :class="
      false ? 'position-sticky blur shadow-blur top-1 z-index-sticky' : ''
    "
    flat
  >
    <v-row class="py-1" align="center">
      <v-col cols="12" sm="6" class="d-flex">
        <div
          class="drawer-toggler py-5 pr-5 cursor-pointer"
          :class="{ active: togglerActive }"
          @click="minifyDrawer"
          v-if="!$vuetify.breakpoint.mobile"
        >
          <div class="drawer-toggler-inner">
            <i class="drawer-toggler-line bg-body"></i>
            <i class="drawer-toggler-line bg-body"></i>
            <i class="drawer-toggler-line bg-body"></i>
          </div>
        </div>
        <v-row class="pa-0 ma-0" align="center">
          <div>
            <v-breadcrumbs class="pb-0 pt-1 px-0">
              <v-breadcrumbs-item class="opacity-5 text-dark">
                {{ $route.meta.groupName }}
              </v-breadcrumbs-item>
              <li
                v-if="$route.name != 'default'"
                class="v-breadcrumbs__divider opacity-5 px-2 text-muted"
              >
                /
              </li>
              <v-breadcrumbs-item
                active-class="active-breadcrumb"
                class="no-default-hover text-dark"
              >
                <template v-if="$route.name === 'default'"></template>
                <template v-else>{{ $route.name }}</template>
              </v-breadcrumbs-item>
              <li
                v-if="$route.meta.operation"
                class="v-breadcrumbs__divider opacity-5 px-2 text-muted"
              >
                /
              </li>
              <v-breadcrumbs-item
              v-if="$route.meta.operation"
                active-class="active-breadcrumb"
                class="no-default-hover text-dark"
              >
                <template>{{ $route.meta.operation }}</template>
              </v-breadcrumbs-item>
            </v-breadcrumbs>
          </div>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        class="d-flex align-center"
        :class="$vuetify.rtl ? 'text-sm-left' : 'text-sm-right'"
      >
        <v-form
          v-if="hasBg"
          @submit.prevent="submit"
          class="navbar-search navbar-search-light d-inline-block ms-auto"
          id="navbar-search-main"
        >
          <v-text-field
            v-model="searchTerm"
            rounded
            hide-details
            outlined
            background-color="rgba(255,255,255,.9)"
            color="rgba(0,0,0,.6)"
            light
            placeholder="Suchen"
            class="input-style font-size-input placeholder-lighter input-alternative input-icon border"
          >
            <!-- <template slot="prepend-inner">
              <v-icon color="rgba(0,0,0,.6)" size=".875rem"
                >fas fa-search</v-icon
              >
            </template> -->
          </v-text-field>
        </v-form>
        <!-- <v-btn
          depressed
          :ripple="false"
          class="font-weight-600 text-body ls-0 text-capitalize pe-0 me-2"
          color="transparent"
          to="/pages/authentication/signup/basic"
        >
          <v-icon size="16">fa fa-user me-sm-2 text-sm</v-icon>
          <span
            class="d-sm-inline font-weight-bold d-none"
            :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
            :style="!hasBg ? 'color: rgba(0,0,0, .6)' : ''"
            >Sign in</span
          >
        </v-btn> -->

        <v-btn
          elevation="0"
          :ripple="false"
          height="43"
          class="font-weight-600 text-capitalize drawer-toggler py-3 px-0 rounded-sm"
          :class="{
            'btn-dark-toggler-hover': !hasBg,
            'btn-toggler-hover': hasBg,
            active: togglerActive,
          }"
          v-if="$vuetify.breakpoint.mobile"
          color="transparent"
          @click="drawerClose"
        >
          <div class="drawer-toggler-inner">
            <i class="drawer-toggler-line text-body"></i>
            <i class="drawer-toggler-line text-body"></i>
            <i class="drawer-toggler-line text-body"></i>
          </div>
        </v-btn>
        <!--         
        <v-btn
          icon
          :ripple="false"
          :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
          class="text-body mx-2"
          :color="linkColor"
          small
          @click="$emit('toggleSettingsDrawer', true)"
        >
          <v-icon size="16">fa fa-cog text-sm</v-icon>
        </v-btn> -->
        <div class="mx-3">
          <v-menu
            transition="slide-y-transition"
            offset-y
            offset-x
            min-width="300"
            max-width="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                :ripple="false"
                :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
                class="text-body"
                :color="linkColor"
                v-bind="attrs"
                v-on="on"
                small
              >
                <v-icon size="16">fa fa-bell text-sm</v-icon>
              </v-btn>
            </template>

            <v-list class="py-0">
              <v-list-item
                v-for="(item, i) in dropdown"
                :key="i"
                class="pa-4 list-item-hover-active"
                @click="$router.push(item.link).catch(() => {})"
              >
                <v-list-item-avatar
                  :size="36"
                  class="my-0 me-4 border-radius-lg"
                  v-if="item.avatar"
                >
                  <v-img
                    :alt="`${item.avatar} avatar`"
                    :src="item.avatar"
                  ></v-img>
                </v-list-item-avatar>

                <v-list-item-avatar
                  :size="36"
                  class="my-0 me-4 border-radius-lg bg-gradient-default"
                  v-if="item.image"
                >
                  <v-img :alt="`${item.image} image`" :src="item.image"></v-img>
                </v-list-item-avatar>

                <v-list-item-avatar
                  :size="36"
                  class="my-0 me-4 border-radius-lg bg-gradient-secondary"
                  v-if="item.icon"
                >
                  <v-img
                    :alt="`${item.icon} icon`"
                    :src="item.icon"
                    class="pa-2"
                    width="10"
                    height="10"
                    contain
                  ></v-img>
                </v-list-item-avatar>

                <v-list-item-content class="pa-0">
                  <v-list-item-title
                    class="text-body-2 ls-0 text-typo font-weight-600 mb-0"
                  >
                    <v-row>
                      <v-col>
                        <h6 class="text-sm font-weight-normal mb-1 text-typo">
                          {{ item.title }}
                        </h6>
                      </v-col>
                    </v-row>
                  </v-list-item-title>

                  <p class="text-xs text-secondary mb-0">
                    <v-icon size="12" class="text-secondary"
                      >fa fa-clock me-1</v-icon
                    >
                    {{ item.time }}
                  </p>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <v-avatar
          color="bg-white"
          class="mr-3"
          width="40"
          height="40"
          min-width="40"
          @click="$router.push('/profile').catch(() => {})"
        >
          <v-icon size="20" class="text-primary text-gradient">
            fas fa-user
          </v-icon>
        </v-avatar>
      </v-col>
    </v-row>
  </v-app-bar>
</template>
<script>
export default {
  name: "app-bar",
  props: {
    background: String,
    hasBg: Boolean,
    linkColor: String,
    toggleActive: Boolean,
    navbarFixed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchTerm: "",
      drawer: false,
      togglerActive: false,
      dropdown: [
        {
          icon: require("@/assets/img/icons/card.svg"),
          title: "Zahlung ausstehend",
          time: "2 days",
          link: "/subscription",
        },
        {
          icon: require("@/assets/img/icons/card.svg"),
          title: "neue Rechnung verfügbar",
          time: "3 days",
          link: "/invoices",
        },
        {
          icon: require("@/assets/img/icons/card.svg"),
          title: "Teammitglied wurde bestätigt",
          time: "3 days",
          link: "/team",
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch("userInformations");
  },
  methods: {
    drawerClose() {
      this.togglerActive = !this.togglerActive;
      this.$emit("drawer-toggle", true);
    },
    minifyDrawer() {
      this.togglerActive = !this.togglerActive;
      this.mini = !this.mini;
      const body = document.getElementsByTagName("body")[0];

      if (body.classList.contains("drawer-mini")) {
        body.classList.remove("drawer-mini");
      } else {
        body.classList.add("drawer-mini");
      }
    },
    submit() {
      this.$router
        .push("/admin/suche?searchTerm=" + this.searchTerm)
        .catch(() => {});
    },
  },
  watch: {
    toggleActive(val) {
      this.togglerActive = val.toString();
    },
  },
};
</script>
