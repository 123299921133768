<template>
  <v-app-bar
    absolute
    :color="background"
    height="auto"
    class=""
    :class="'position-sticky py-2'"
    flat
  >
    <v-container>
      <v-row>
        <v-col cols="3" class="d-flex align-center">
          <p
            class="
              font-weight-bolder
              ms-lg-0 ms-4
              mb-0
              text-white text-sm text-h3
            "
            @click="$router.push('/').catch(() => {})"
            style="cursor: pointer"
          >
            BE<span style="color: #e2b007">X</span>IKON
          </p>
        </v-col>

        <v-col cols="6" class="mx-auto text-center">
          <template v-if="!$vuetify.breakpoint.mobile">
            <v-menu
              open-on-hover
              offset-y
              bottom
              min-width="360"
              max-width="600"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :ripple="false"
                  color="transparent"
                  :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
                  class="text-white text-capitalize ls-0 align-center"
                  depressed
                  link
                >
                  Kategorien
                </v-btn>
              </template>

              <v-list class="border-radius-xl overflow-hidden">
                <template>
                  <app-bar-categories></app-bar-categories>
                </template>
              </v-list>
            </v-menu>
            <v-btn
              :ripple="false"
              color="transparent"
              :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
              class="text-white text-capitalize ls-0 align-center"
              depressed
              link
              :to="'/kontakt'"
            >
              Kontakt
            </v-btn>
            <v-btn
              v-if="userInformations"
              :ripple="false"
              color="transparent"
              :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
              class="text-white text-capitalize ls-0 align-center"
              depressed
              link
              :to="'/profil'"
            >
              Profil
            </v-btn>
            <v-btn
              v-if="userInformations && userInformations.admin"
              :ripple="false"
              color="transparent"
              :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
              class="text-white text-capitalize ls-0 align-center"
              depressed
              link
              :to="'/admin'"
            >
              Admin
            </v-btn>
          </template>
        </v-col>

        <v-col cols="3" class="text-right">
          <template v-if="!$vuetify.breakpoint.mobile">
            <v-btn
              v-if="userInformations"
              @click="logout"
              elevation="0"
              color="#fff"
              class="font-weight-bolder py-4 px-4 ms-2"
              :class="'btn-primary bg-warning'"
              href="javascript:;"
              small
            >
              Ausloggen
            </v-btn>
            <v-btn
              v-else
              @click="$store.commit('popLogin', true)"
              elevation="0"
              color="#fff"
              class="font-weight-bolder py-4 px-4 ms-2"
              :class="'btn-primary bg-warning'"
              href="javascript:;"
              small
            >
              Einloggen
            </v-btn>
          </template>

          <v-btn
            v-show="$vuetify.breakpoint.mobile"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-600
              text-capitalize
              drawer-toggler
              btn-toggler-hover
              py-3
              px-6
              rounded-sm
            "
            color="transparent"
            @click="dialog = true"
          >
            <div class="drawer-toggler-inner">
              <i class="drawer-toggler-line" :class="{ 'bg-white': hasBg }"></i>
              <i class="drawer-toggler-line" :class="{ 'bg-white': hasBg }"></i>
              <i class="drawer-toggler-line" :class="{ 'bg-white': hasBg }"></i>
            </div>
          </v-btn>

          <v-dialog
            v-model="dialog"
            content-class="position-absolute top-0"
            width="95%"
          >
            <v-card class="card-shadow card-padding" color="#355042">
              <v-card-title
                class="
                  pt-0
                  px-4
                  card-padding
                  text-h3
                  font-weight-bold
                  text-white
                  justify-space-between
                  border-bottom
                "
              >
                <div>BE<span style="color: #e2b007">X</span>IKON</div>

                <v-btn icon width="31" :ripple="false" @click="dialog = false">
                  <v-icon size="18" class="text-white">fas fa-times</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text class="card-padding pb-0  px-0">
                <!-- <v-btn
                  v-for="item in links"
                  :key="item.name"
                  :ripple="false"
                  text
                  class="text-typo text-capitalize ls-0"
                  depressed
                  link
                  :to="item.link"
                >
                  {{ item.name }}
                  <v-spacer></v-spacer>
                </v-btn> -->

                <v-btn
                  :ripple="false"
                  
                  :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
                  class="text-white text-capitalize ls-0 align-center text-h5"
                  depressed
                  text
                  link
                  :to="'/kontakt'"
                  @click="dialog = false"
                >
                  Kontakt
                </v-btn>
                <v-btn
                  v-if="userInformations"
                  :ripple="false"
                 
                  :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
                  class="text-white text-capitalize ls-0 align-center  text-h5"
                  depressed
                  text
                  link
                  :to="'/profil'"
                  @click="dialog = false"
                >
                  Profil
                </v-btn>
                <v-btn
                  v-if="userInformations && userInformations.admin"
                  :ripple="false"
                  :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
                  class="text-white text-capitalize ls-0 align-center  text-h5"
                  depressed
                  text
                  link
                  :to="'/admin'"
                  @click="dialog = false"
                >
                  Admin
                </v-btn>

                <v-menu open-on-hover offset-y bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      :ripple="false"
                      :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
                      class="text-white text-capitalize ls-0  text-h5"
                      depressed
                      link
                      text
                    >
                      Kategorien
                    </v-btn>
                  </template>

                  <v-list class="border-radius-xl overflow-hidden">
                    <template>
                      <app-bar-categories></app-bar-categories>
                    </template>
                  </v-list>
                </v-menu>

                <div class="border-bottom my-7"></div>

                <v-btn
              v-if="userInformations"
              @click="logout"
              elevation="0"
              color="#fff"
              class="font-weight-bolder py-4 px-4 ms-2"
              :class="'btn-primary bg-warning'"
              href="javascript:;"
              small
            >
              Ausloggen
            </v-btn>
            <v-btn
              v-else
              @click="$store.commit('popLogin', true)"
              elevation="0"
              color="#fff"
              class="font-weight-bolder py-4 px-4 ms-2"
              :class="'btn-primary bg-warning'"
              href="javascript:;"
              small
            >
              Einloggen
            </v-btn>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="loginDialog" max-width="500px">
      <v-card class="card-shadow border-radius-xl">
        <v-toolbar elevation="0" class="px-5">
          <v-spacer></v-spacer>
          <v-btn icon @click="loginDialog = false">
            <v-icon class="text-secondary">fas fa-times</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="card-padding">
          <v-row class="ma-0 pa-0 justify-center">
            <div>
              <p
                class="
                  font-weight-bolder
                  ms-lg-0 ms-4
                  mb-0
                  text-primary text-sm text-h1
                "
                @click="$router.push('/').catch(() => {})"
                style="cursor: pointer"
              >
                BE<span style="color: #e2b007">X</span>IKON
              </p>
            </div>
          </v-row>

          <v-container class="pa-0">
            <div class="card-padding pt-0">
              <p class="text-danger">
                {{ authMessage.message }}
              </p>
              <p>
                {{
                  $route.query.message == "notAuthorized"
                    ? "Nicht authorisiert!"
                    : ""
                }}
              </p>
              <v-text-field
                hide-details
                outlined
                background-color="#F3F5F6"
                color="rgba(0,0,0,.6)"
                light
                height="40"
                placeholder="E-Mail-Adresse"
                v-model="form.email"
                :rules="emailRules"
                class="
                  input-style
                  font-size-input
                  border border-radius-md
                  placeholder-lighter
                  text-color-light
                  mb-4
                "
              ></v-text-field>

              <v-text-field
                hide-details
                outlined
                background-color="#F3F5F6"
                color="rgba(0,0,0,.6)"
                light
                height="40"
                placeholder="Passwort"
                v-model="form.password"
                :rules="min8"
                type="password"
                class="
                  input-style
                  font-size-input
                  border border-radius-md
                  placeholder-lighter
                  text-color-light
                  mb-4
                "
              ></v-text-field>

              <v-btn
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-bold
                  text-uppercase
                  btn-default
                  bg-primary
                  py-2
                  px-6
                  me-2
                  mt-6
                  mb-2
                  w-100
                "
                color="primary"
                small
                @click="submitForm"
                >EINLOGGEN</v-btn
              >
              <h5
                class="text-h6 text-primary text-center mt-5 font-weight-normal"
              >
                Du hast noch keinen Account?
              </h5>
              <div>
                <v-btn
                  block
                  v-if="!userInformations"
                  :elevation="0"
                  @click="$store.commit('popRegister', true)"
                  class="
                    font-weight-bold
                    text-xs
                    btn-default
                    bg-warning
                    py-5
                    px-6
                    mt-6
                    text-uppercase
                  "
                  color="primary"
                  large
                >
                  Jetzt Registrieren
                </v-btn>
              </div>
            </div>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="ratingDialog" max-width="500px">
      <v-card class="card-shadow border-radius-xl">
        <v-toolbar elevation="0" class="px-5">
          <v-spacer></v-spacer>
          <v-btn icon @click="ratingDialog = false">
            <v-icon class="text-secondary">fas fa-times</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="card-padding">
          <v-row class="ma-0 pa-0 justify-center">
            <div>
              <p
                class="
                  font-weight-bolder
                  ms-lg-0 ms-4
                  mb-0
                  text-primary text-sm text-h3
                  mb-5
                  text-start
                "
              >
                Dein Eindruck
              </p>
            </div>
          </v-row>

          <v-card color="#FCF3D6" class="pa-5">
            <p
              class="
                font-weight-bolder
                ms-lg-0 ms-4
                mb-0
                text-body text-sm text-h5
                mb-5
                text-center
              "
            >
              Wie viele Sterne gibst du?
            </p>
            <div class="d-flex flex-column align-start">
              <v-rating
                class="mx-auto"
                v-model="ratingForm.rating"
                bg-color="orange-lighten-1"
                color="#E2B007"
                size="35"
              ></v-rating>
            </div>
          </v-card>

          <v-container class="pa-0">
            <div class="card-padding pt-0">
              <v-btn
                elevation="0"
                :ripple="false"
                height="43"
                :disabled="!ratingForm.rating"
                class="
                  font-weight-bold
                  text-uppercase
                  btn-default
                  bg-primary
                  py-2
                  px-6
                  me-2
                  mt-6
                  mb-2
                  w-100
                "
                color="primary"
                small
                @click="submitRating"
                >Bewertung abgeben</v-btn
              >
            </div>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="suggestionDialog" max-width="500px">
      <v-card class="card-shadow border-radius-xl">
        <v-toolbar elevation="0" class="px-5">
          <v-spacer></v-spacer>
          <v-btn icon @click="suggestionDialog = false">
            <v-icon class="text-secondary">fas fa-times</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="card-padding">
          <v-row class="ma-0 pa-0 justify-center">
            <div>
              <p
                class="
                  font-weight-bolder
                  ms-lg-0 ms-4
                  mb-0
                  text-primary text-sm text-h1
                "
                @click="$router.push('/').catch(() => {})"
                style="cursor: pointer"
              >
                BE<span style="color: #e2b007">X</span>IKON
              </p>
            </div>
            <div>
              <p
                class="
                  font-weight-bolder
                  ms-lg-0 ms-4
                  mb-0
                  text-primary text-sm text-h5
                  mb-5
                "
                @click="$router.push('/').catch(() => {})"
                style="cursor: pointer"
              >
                Vorschlag für einen fehlenden Begriff
              </p>
            </div>
          </v-row>

          <v-container class="pa-0">
            <div class="card-padding pt-0">
              <v-text-field
                hide-details
                outlined
                background-color="#F3F5F6"
                color="rgba(0,0,0,.6)"
                light
                height="40"
                placeholder="Begriff"
                v-model="suggestionForm.expression"
                :rules="emailRules"
                class="
                  input-style
                  font-size-input
                  border border-radius-md
                  placeholder-lighter
                  text-color-light
                  mb-4
                "
              ></v-text-field>

              <v-textarea
                hide-details
                outlined
                background-color="#F3F5F6"
                color="rgba(0,0,0,.6)"
                light
                placeholder="Erklärung"
                v-model="suggestionForm.explanation"
                :rules="min8"
                type="text"
                class="
                  input-style
                  font-size-input
                  border border-radius-md
                  placeholder-lighter
                  text-color-light
                  mb-4
                "
              ></v-textarea>

              <v-textarea
                hide-details
                outlined
                background-color="#F3F5F6"
                color="rgba(0,0,0,.6)"
                light
                placeholder="Beispiel"
                v-model="suggestionForm.example"
                :rules="min8"
                type="text"
                class="
                  input-style
                  font-size-input
                  border border-radius-md
                  placeholder-lighter
                  text-color-light
                  mb-4
                "
              ></v-textarea>

              <v-checkbox v-model="suggestionForm.gdpr" required>
                <template v-slot:label>
                  <div>
                    Ich habe die
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <a
                          target="_blank"
                          href="/datenschutz"
                          @click.stop
                          v-on="on"
                        >
                          Datenschutzbestimmungen
                        </a>
                      </template>
                      Datenschutz
                    </v-tooltip>
                    gelesen und stimme zu. Ich bin mir bewusst, dass meine Daten
                    zur Prüfung des Vorschlages übermittelt und gespeichert
                    werden.
                  </div>
                </template>
              </v-checkbox>

              <v-btn
                elevation="0"
                :ripple="false"
                height="43"
                :disabled="
                  !suggestionForm.expression ||
                  !suggestionForm.explanation ||
                  !suggestionForm.example ||
                  !suggestionForm.gdpr
                "
                class="
                  font-weight-bold
                  text-uppercase
                  btn-default
                  bg-primary
                  py-2
                  px-6
                  me-2
                  mt-6
                  mb-2
                  w-100
                "
                color="primary"
                small
                @click="submitSuggestion"
                >Vorschlag einreichen</v-btn
              >
            </div>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="registerDialog" max-width="500px">
      <v-card class="card-shadow border-radius-xl">
        <v-toolbar elevation="0" class="px-5">
          <v-btn v-if="!accountCreated" icon @click="(registerDialog = false), (loginDialog = true)">
            <v-icon class="text-secondary">fas fa-chevron-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn icon @click="(registerDialog = false), (loginDialog = false)">
            <v-icon class="text-secondary">fas fa-times</v-icon>
          </v-btn>
        </v-toolbar>
        <v-row class="ma-0 pa-0 justify-center">
          <div>
            <p class="font-weight-bolder text-primary text-sm text-h1">
              BE<span style="color: #e2b007">X</span>IKON
            </p>
          </div>
        </v-row>
        <v-card-text class="card-padding">
          <v-container class="pa-0">
            <div class="card-padding pt-0">
              <div v-if="!accountCreated">
                <p class="text-danger">
                  {{ authMessage.message }}
                </p>
                <v-row>
                  <v-col cols="12" md="6" class="ma-0 pa-0">
                    <v-text-field
                      hide-details
                      outlined
                      background-color="#F3F5F6"
                      color="rgba(0,0,0,.6)"
                      light
                      height="40"
                      placeholder="Benutzername"
                      v-model="form.username"
                      :rules="rules"
                      class="
                        mx-2
                        input-style
                        font-size-input
                        border border-radius-md
                        placeholder-lighter
                        text-color-light
                        mb-4
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="ma-0 pa-0">
                    <v-text-field
                      hide-details
                      outlined
                      background-color="#F3F5F6"
                      color="rgba(0,0,0,.6)"
                      light
                      height="40"
                      placeholder="E-Mail-Adresse"
                      v-model="form.email"
                      :rules="emailRules"
                      class="
                        mx-2
                        input-style
                        font-size-input
                        border border-radius-md
                        placeholder-lighter
                        text-color-light
                        mb-4
                      "
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="ma-0 pa-0">
                    <v-text-field
                      hide-details
                      outlined
                      background-color="#F3F5F6"
                      color="rgba(0,0,0,.6)"
                      light
                      height="40"
                      placeholder="Passwort"
                      v-model="form.password"
                      type="password"
                      :rules="min8"
                      class="
                        mx-2
                        input-style
                        font-size-input
                        border border-radius-md
                        placeholder-lighter
                        text-color-light
                        mb-4
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="ma-0 pa-0">
                    <v-text-field
                      hide-details
                      outlined
                      background-color="#F3F5F6"
                      color="rgba(0,0,0,.6)"
                      light
                      height="40"
                      placeholder="Passwort wiederholen"
                      v-model="form.passwordRepeat"
                      type="password"
                      :rules="min8"
                      class="
                        mx-2
                        input-style
                        font-size-input
                        border border-radius-md
                        placeholder-lighter
                        text-color-light
                        mb-4
                      "
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-checkbox v-model="form.gdpr" required>
                  <template v-slot:label>
                    <div>
                      Ich habe die
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <a
                            target="_blank"
                            href="/datenschutz"
                            @click.stop
                            v-on="on"
                          >
                            Datenschutzbestimmungen
                          </a>
                        </template>
                        Datenschutz
                      </v-tooltip>
                      gelesen und stimme zu.
                    </div>
                  </template>
                </v-checkbox>

                <v-btn
                  elevation="0"
                  :ripple="false"
                  height="43"
                  :disabled="
                    !form.username ||
                    !form.email ||
                    !form.password ||
                    !form.passwordRepeat ||
                    !form.gdpr
                  "
                  :class="
                    !form.username ||
                    !form.email ||
                    !form.password ||
                    !form.passwordRepeat ||
                    !form.gdpr
                      ? ''
                      : 'bg-primary'
                  "
                  class="
                    font-weight-bold
                    text-uppercase
                    btn-default
                    py-2
                    px-6
                    me-2
                    mt-6
                    mb-2
                    w-100
                  "
                  color="#5e72e4"
                  small
                  @click="submitFormRegister"
                  ><span v-if="!updatingAuth">Registrieren</span>
                  <v-progress-circular
                    v-else
                    indeterminate
                    color="white"
                  ></v-progress-circular>
                </v-btn>
                <h5
                  class="
                    text-h5 text-secondary text-center
                    mt-5
                    font-weight-normal
                  "
                >
                  Du hast bereits einen Account?
                </h5>

                <div>
                  <v-btn
                    block
                    v-if="!userInformations"
                    :elevation="0"
                    @click="$store.commit('popLogin', true)"
                    class="
                      font-weight-bold
                      text-xs
                      btn-default
                      bg-warning
                      py-5
                      px-6
                      mt-6
                      mb-2
                      me-2
                      ms-auto
                    "
                    color="warning"
                  >
                    Einloggen
                  </v-btn>
                </div>
              </div>
              <div v-if="userInformations">
                <h5 class="text-h5 mb-4 text-secondary font-weight-normal">
                  Bitte bestätige Deine E-Mail-Adresse.
                </h5>
                <h5 class="text-h5 text-secondary font-weight-normal">
                  Wir haben Dir einen Code per E-Mail gesendet, bitte gebe Ihn
                  hier ein:
                </h5>
                <p class="text-danger">
                  {{ authMessage.message }}
                </p>
                <v-text-field
                      hide-details
                      outlined
                      background-color="#F3F5F6"
                      color="rgba(0,0,0,.6)"
                      light
                      height="40"
                      placeholder="6-Stelliger Code"
                      v-model="form.verificationCode"
                      type="number"
                      :rules="min6"
                      class="
                        
                        input-style
                        font-size-input
                        border border-radius-md
                        placeholder-lighter
                        text-color-light
                        mb-4
                      "
                    ></v-text-field>
                <!-- <v-text-field
                  type="number"
                  label="6-Stelliger Code"
                  placeholder="6-Stelliger Code"
                  color="#e91e63"
                  required
                  v-model="form.verificationCode"
                  class="font-size-input input-style mt-10"
                  :rules="min6"
                ></v-text-field> -->
                <v-btn
                  elevation="0"
                  :ripple="false"
                  height="43"
                  :disabled="form.verificationCode.length != 6"
                  class="
                    font-weight-bold
                    text-uppercase
                    btn-default
                    py-2
                    px-6
                    me-2
                    mt-6
                    mb-2
                    w-100
                  "
                  :class="
                    form.verificationCode.length != 6
                      ? ''
                      : 'bg-primary'
                  "
                  color="#5e72e4"
                  small
                  @click="verifyEmail"
                  ><span v-if="!updatingAuth">Code bestätigen</span>
                  <v-progress-circular
                    v-else
                    indeterminate
                    color="white"
                  ></v-progress-circular>
                </v-btn>

                <v-btn
                  elevation="0"
                  :ripple="false"
                  height="43"
                  class="
                    font-weight-bold
                    text-uppercase
                    btn-default
                    bg-warning
                    py-2
                    px-6
                    me-2
                    mt-6
                    mb-2
                    w-100
                  "
                  color="#5e72e4"
                  small
                  :disabled="codeSent"
                  @click="$store.dispatch('resendCode', {email: userInformations.email, resendActivation: true})"
                  ><span v-if="!updatingAuth"
                    >Neue{{ codeSent ? "r" : "n" }} Code
                    {{ codeSent ? "wurde gesendet" : "senden" }}</span
                  >
                  <v-progress-circular
                    v-else
                    indeterminate
                    color="white"
                  ></v-progress-circular>
                </v-btn>
                <p class="text-center">{{ userInformations.email }}</p>
              </div>
            </div>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>
<script>
import AppBarPages from "./Widgets/AppBarPages.vue";
import AppBarCategories from "./Widgets/AppBarCategories.vue";
import AppBarAuthentication from "./Widgets/AppBarAuthentication.vue";
import AppBarApplications from "./Widgets/AppBarApplications.vue";
import AppBarEcommerce from "./Widgets/AppBarEcommerce.vue";
import AppBarDocs from "./Widgets/AppBarDocs.vue";
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";

Vue.use(VueSweetalert2);

export default {
  name: "app-bar-default",
  components: {
    AppBarPages,
    AppBarCategories,
    AppBarAuthentication,
    AppBarApplications,
    AppBarEcommerce,
    AppBarDocs,
  },
  props: {
    background: String,
    hasBg: Boolean,
    linkColor: String,
  },
  mounted() {
    this.$store.dispatch("userInformations");
    this.$store.dispatch("getGlossary");
    this.$store.dispatch("getCategory");
  },
  computed: {
    popLogin() {
      return this.$store.getters.popLogin;
    },
    accountCreated() {
      return this.$store.getters.accountCreated;
    },
    updatingAuth() {
      return this.$store.getters.updatingAuth;
    },
    authMessage() {
      return this.$store.getters.authMessage;
    },
    popSuggestion() {
      return this.$store.getters.popSuggestion;
    },
    popRating() {
      return this.$store.getters.popRating;
    },
    popRegister() {
      return this.$store.getters.popRegister;
    },
    userInformations() {
      return this.$store.getters.userInformations;
    },
    accountCompleted() {
      return this.$store.getters.accountCompleted;
    },
    codeSent() {
      return this.$store.getters.codeSent;
    },
    selectedGlossary() {
      return this.$store.getters.selectedGlossary;
    },
  },
  watch: {
    popLogin() {
      console.log('watch popLogin')
      if (this.popLogin) {
        this.loginDialog = true;
        this.registerDialog = false;
      } else {
        this.loginDialog = false;
      }
    },
    popRegister() {
      console.log('watch popRegister')
      if (this.popRegister) {
        this.registerDialog = true;
        this.loginDialog = false;
      } else {
        this.registerDialog = false;
      }
    },
    popRating() {
      if (this.popRating) {
        this.ratingDialog = true;
      } else {
        this.ratingDialog = false;
      }
    },
    popSuggestion() {
      if (this.popSuggestion) {
        this.suggestionDialog = true;
      } else {
        this.suggestionDialog = false;
      }
    },
    loginDialog() {
      this.$store.commit("popLogin", this.loginDialog);
    },
    registerDialog() {
      this.$store.commit("popRegister", this.registerDialog);
    },
    suggestionDialog() {
      this.$store.commit("popSuggestion", this.suggestionDialog);
    },
    ratingDialog() {
      this.$store.commit("popRating", this.ratingDialog);
    },
    userInformations() {
      if(this.updatingAuth) return;
      if (this.userInformations) {
        this.loginDialog = false;
        this.registerDialog = false;
        this.$store.commit("popLogin", this.loginDialog);
        this.$store.commit("popRegister", this.registerDialog);
      } else {
        this.$router.push("/").catch(() => {});
      }
    },
  },
  data() {
    return {
      suggestionForm: {},
      ratingForm: {},
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => v.length <= 10 || "Name must be less than 10 characters",
      ],
      emailRules: [
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "Bitte gebe eine gültige E-Mail-Adresse an",
      ],
      rules: [
        (value) => !!value || "Erforderlich.",
        (value) => (value && value.length >= 3) || "Min 3 Zeichen",
      ],
      min8: [(value) => (value && value.length >= 7) || "Min 8 Zeichen"],
      min6: [
        (value) =>
          (value && value.length > 5 && value.length < 7) || "6 Zeichen",
      ],
      loginDialog: false,
      registerDialog: false,
      ratingDialog: false,
      suggestionDialog: false,
      dialog: false,
      form: {
        verificationCode: "",
      },
      links: [
        {
          name: "kategorien",
          link: "/kategorien",
        },
        {
          name: "Kontakt",
          link: "/kontakt",
        },
        {
          name: "Profil",
          link: "/profil",
        },
      ],
    };
  },
  methods: {
    checkLoginState() {
      if (this.userInformations) {
        if (this.userInformations.isVerified) {
          return true;
        } else {
          this.$store.commit("accountCreated", true);
          this.$store.commit("popRegister", true);
          return false;
        }
      } else {
        this.$store.commit("popLogin", true);
        return false;
      }
    },
    logout() {
      this.$store.dispatch("logoutModal");
    },
    submitSuggestion() {
      
      this.$store
        .dispatch("postSuggestion", this.suggestionForm)
        .then((res) => {
          this.$swal(
            "Danke!",
            "Dein Vorschlag wurde eingereicht!",
            "success"
          ).then((response) => {
            if (!response.isConfirmed) {
              throw new Error(response.statusText);
            }
            this.suggestionDialog = false;
          });
        });
    },
    submitRating() {
      
      this.ratingForm.glossaryId = this.selectedGlossary.id;
      this.$store.dispatch("postRating", this.ratingForm).then((res) => {
        this.$swal(
          "Danke!",
          "Deine Bewertung wurde eingereicht!",
          "success"
        ).then((response) => {
          if (!response.isConfirmed) {
            throw new Error(response.statusText);
          }
          this.ratingDialog = false;
        });
      });
    },
    submitForm() {
      let payload = {
        email: this.form.email,
        password: this.form.password,
      };
      this.$store.dispatch("loginModal", payload);
    },
    submitFormRegister() {
      let payload = {
        email: this.form.email,
        password: this.form.password,
        username: this.form.username,
      };
      this.$store.dispatch("signup", payload);
    },
    verifyEmail() {
      let payload = {
        code: this.form.verificationCode,
      };
      this.$store.dispatch("verifyEmail", payload);
    },
  },
};
</script>
